/* eslint-disable max-lines */
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  activeWalletRequest,
  buyPumpWalletRequest,
  claimSolRequest,
  deletePumpWalletRequest,
  getListPumpTokenRequest,
  getListPumpWalletRequest,
  getListWalletBuyPumpRequest,
  getListWalletSellPumpRequest,
  getListWalletVolumePumpRequest,
  getProfitTradeRequest,
  getPumpBootTxListRequest,
  getPumpBootVolumeTxListRequest,
  getSettingTradeRequest,
  importPumpBootTxRequest,
  importPumpBootVolumeTxRequest,
  importPumpTokenRequest,
  importPumpWalletRequest,
  importWalletBuyPumpRequest,
  importWalletSellPumpRequest,
  importWalletVolumePumpRequest,
  resetPumpInfoRequestStatus,
  sellPumpWalletRequest,
  startFakeBuyPumpRequest,
  startFakeSellPumpRequest,
  startFakeVolumePumpRequest,
  unActiveWalletRequest,
  updateListTraderActive,
  updateSettingTradeRequest,
  updateVolumeBuyPumpRequest,
  updateVolumeSellPumpRequest,
  updateVolumeVolumePumpRequest,
} from '@/stores/slices/pump/pump';

import { selectPump } from '@/stores/slices/pump/selector';

const usePumpHook: any = () => {
  const {
    importWalletBuyPumpStatus,
    importWalletSellPumpStatus,
    importWalletVolumePumpStatus,
    getListWalletBuyPumpStatus,
    getListWalletSellPumpStatus,
    getListWalletVolumePumpStatus,
    startFakeBuyPumpStatus,
    startFakeSellPumpStatus,
    startFakeVolumePumpStatus,
    updateVolumeBuyPumpStatus,
    updateVolumeSellPumpStatus,
    updateVolumeVolumePumpStatus,
    getPumpBootTxListStatus,
    importPumpBootTxStatus,
    getPumpBootVolumeTxListStatus,
    importPumpBootVolumeTxStatus,
    claimSolStatus,
    listWalletBuy,
    listWalletSell,
    listWalletVolume,
    pumpBootTxList,
    pumpBootVolumeTxList,
    listSignaturePumpBuy,
    listSignaturePumpSell,
    listSignaturePumpVolume,
    getListPumpTokenStatus,
    listPumpToken,
    importPumpTokenStatus,
    getListPumpWalletStatus,
    importPumpWalletStatus,
    listPumpWallet,
    listTraderActive,
    deletePumpWalletStatus,
    buyPumpWalletStatus,
    sellPumpWalletStatus,
    activeWalletStatus,
    unActiveWalletStatus,
    getSettingTradeStatus,
    settingTrade,
    updateSettingTradeStatus,
    getProfitTradeStatus,
    listProfitTrade,
  } = useAppSelector(selectPump);

  const dispatch = useAppDispatch();

  const requestImportWalletBuy = (params: any) =>
    dispatch(importWalletBuyPumpRequest(params));

  const requestImportWalletSell = (params: any) =>
    dispatch(importWalletSellPumpRequest(params));

  const requestImportWalletVolume = (params: any) =>
    dispatch(importWalletVolumePumpRequest(params));

  const requestGetListWalletBuyPump = (params: any) =>
    dispatch(getListWalletBuyPumpRequest(params));

  const requestGetListWalletSellPump = (params: any) =>
    dispatch(getListWalletSellPumpRequest(params));

  const requestGetListWalletVolumePump = (params: any) =>
    dispatch(getListWalletVolumePumpRequest(params));

  const requestPumpStartFakeBuy = (params: any) =>
    dispatch(startFakeBuyPumpRequest(params));

  const requestPumpStartFakeSell = (params: any) =>
    dispatch(startFakeSellPumpRequest(params));

  const requestPumpStartFakeVolume = (params: any) =>
    dispatch(startFakeVolumePumpRequest(params));

  const requestPumpUpdateVolumeBuy = (params: any) =>
    dispatch(updateVolumeBuyPumpRequest(params));
  const requestPumpUpdateVolumeSell = (params: any) =>
    dispatch(updateVolumeSellPumpRequest(params));
  const requestPumpUpdateVolumeVolume = (params: any) =>
    dispatch(updateVolumeVolumePumpRequest(params));
  const requestPumpBootTxList = (params: any) =>
    dispatch(getPumpBootTxListRequest(params));

  const requestImportPumpBootTx = (params: any) =>
    dispatch(importPumpBootTxRequest(params));

  const requestPumpBootVolumeTxList = (params: any) =>
    dispatch(getPumpBootVolumeTxListRequest(params));

  const requestPumpImportBootVolumeTx = (params: any) =>
    dispatch(importPumpBootVolumeTxRequest(params));

  const requestResetPump = () => dispatch(resetPumpInfoRequestStatus());

  const requestClaimSol = (params: any) => dispatch(claimSolRequest(params));

  const requestGetListPumpToken = (params: any) =>
    dispatch(getListPumpTokenRequest(params));

  const requestImportPumpToken = (params: any) =>
    dispatch(importPumpTokenRequest(params));

  const requestGetListPumpWallet = (params: any) =>
    dispatch(getListPumpWalletRequest(params));

  const requestImportPumpWallet = (params: any) =>
    dispatch(importPumpWalletRequest(params));

  const requestDeletePumpWallet = (params: any) =>
    dispatch(deletePumpWalletRequest(params));

  const requestBuyPumpWallet = (params: any) =>
    dispatch(buyPumpWalletRequest(params));

  const requestSellPumpWallet = (params: any) =>
    dispatch(sellPumpWalletRequest(params));

  const requestActiveWallet = (params: any) =>
    dispatch(activeWalletRequest(params));

  const requestUnActiveWallet = (params: any) =>
    dispatch(unActiveWalletRequest(params));

  const requestGetSettingTrade = (params: any) =>
    dispatch(getSettingTradeRequest(params));

  const requestUpdateSettingTrade = (params: any) =>
    dispatch(updateSettingTradeRequest(params));

  const requestGetProfitTrade = (params: any) =>
    dispatch(getProfitTradeRequest(params));

  return {
    requestResetPump,
    requestImportWalletBuy,
    requestImportWalletSell,
    requestImportWalletVolume,
    requestGetListWalletBuyPump,
    requestGetListWalletSellPump,
    requestGetListWalletVolumePump,
    requestPumpStartFakeBuy,
    requestPumpStartFakeSell,
    requestPumpStartFakeVolume,
    requestPumpUpdateVolumeBuy,
    requestPumpUpdateVolumeSell,
    requestPumpUpdateVolumeVolume,
    requestPumpBootTxList,
    requestImportPumpBootTx,
    requestPumpBootVolumeTxList,
    requestPumpImportBootVolumeTx,
    requestClaimSol,
    requestGetListPumpToken,
    requestImportPumpToken,
    requestGetListPumpWallet,
    requestImportPumpWallet,
    requestDeletePumpWallet,
    requestBuyPumpWallet,
    requestSellPumpWallet,
    requestActiveWallet,
    requestUnActiveWallet,
    requestGetSettingTrade,
    requestUpdateSettingTrade,
    requestGetProfitTrade,
    importWalletBuyPumpStatus,
    importWalletSellPumpStatus,
    importWalletVolumePumpStatus,
    getListWalletBuyPumpStatus,
    getListWalletSellPumpStatus,
    getListWalletVolumePumpStatus,
    startFakeBuyPumpStatus,
    startFakeSellPumpStatus,
    startFakeVolumePumpStatus,
    updateVolumeBuyPumpStatus,
    updateVolumeSellPumpStatus,
    updateVolumeVolumePumpStatus,
    getPumpBootTxListStatus,
    getPumpBootVolumeTxListStatus,
    importPumpBootVolumeTxStatus,
    importPumpBootTxStatus,
    deletePumpWalletStatus,
    claimSolStatus,
    listWalletBuy,
    listWalletSell,
    listWalletVolume,
    pumpBootTxList,
    pumpBootVolumeTxList,
    listSignaturePumpBuy,
    listSignaturePumpSell,
    listSignaturePumpVolume,
    getListPumpTokenStatus,
    listPumpToken,
    importPumpTokenStatus,
    getListPumpWalletStatus,
    importPumpWalletStatus,
    listPumpWallet,
    updateListTraderActive,
    listTraderActive,
    buyPumpWalletStatus,
    sellPumpWalletStatus,
    activeWalletStatus,
    unActiveWalletStatus,
    getSettingTradeStatus,
    settingTrade,
    updateSettingTradeStatus,
    getProfitTradeStatus,
    listProfitTrade,
  };
};

export default usePumpHook;
