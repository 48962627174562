import { AccountLayout, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import * as solanaWeb3 from '@solana/web3.js';
import {
  Connection,
  Keypair,
  LAMPORTS_PER_SOL,
  PublicKey,
  Transaction,
} from '@solana/web3.js';
import Big from 'big.js';
import BigNumber from 'bignumber.js';
import bs58 from 'bs58';
import * as buffer from 'buffer';
import dayjs from 'dayjs';
import _ from 'lodash';
import { DEFAULT_NULL_INDEX, SNIP_STATUS } from 'src/constants';
import { RequestStatus } from 'src/constants/API';

import {
  ErrorsType,
  IImageValidation,
  ImageListType,
  ResolutionType,
} from 'src/types';
window.Buffer = buffer.Buffer;

export const isValidEmail = (email: string) => {
  // Basic email format validation using a regular expression
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export const checkIsRequesting = (statuses: string[]) => {
  let isRequesting = false;
  statuses.forEach((status: string) => {
    if (status === RequestStatus.REQUESTING) {
      isRequesting = true;
      return;
    }
  });
  return isRequesting;
};

export const isImage = (src = ''): boolean => {
  const dotIndex = src.lastIndexOf('.');
  const fileExtension = src.substring(dotIndex);
  const regex = RegExp(/(gif|jpg|jpeg|tiff|png|heic|heif)$/, 'gim');
  return !!regex.exec(fileExtension);
};

export const transformTokenDetails = (data: any) => {
  return {
    name: data.name,
    symbol: data.symbol,
    description: data.description,
    decimal: data.decimal,
    logo: data.logo,
    website: data.website,
    telegram: data.telegram,
    twitter: data.twitter,
  };
};

export const isResolutionValid = (
  image: HTMLImageElement,
  resolutionType: ResolutionType,
  resolutionWidth: number = 0,
  resolutionHeight: number = 1,
): boolean => {
  if (!resolutionWidth || !resolutionHeight || !image.width || !image.height)
    return true;
  switch (resolutionType) {
    case 'absolute': {
      if (image.width === resolutionWidth && image.height === resolutionHeight)
        return true;
      break;
    }
    case 'ratio': {
      const ratio = resolutionWidth / resolutionHeight;
      if (image.width / image.height === ratio) return true;
      break;
    }
    case 'less': {
      if (image.width <= resolutionWidth && image.height <= resolutionHeight)
        return true;
      break;
    }
    case 'more': {
      if (image.width >= resolutionWidth && image.height >= resolutionHeight)
        return true;
      break;
    }
    default:
      break;
  }
  return false;
};

export const isImageValid = (fileType: string) => {
  if (fileType.includes('image')) {
    return true;
  }
  return false;
};

export const isMaxFileSizeValid = (fileSize: number, maxFileSize?: number) => {
  return maxFileSize ? fileSize <= maxFileSize : true;
};

export const isAcceptTypeValid = (
  acceptType: string[] | undefined,
  fileName: string,
) => {
  if (acceptType && acceptType.length > 0) {
    const type: string = fileName.split('.').pop() || '';
    if (
      acceptType.findIndex(item => item.toLowerCase() === type.toLowerCase()) <
      0
    )
      return false;
  }
  return true;
};

export const isMaxNumberValid = (
  totalNumber: number,
  maxNumber: number,
  keyUpdate: number,
) => {
  if (maxNumber !== 0 && !maxNumber) return true;
  if (keyUpdate === DEFAULT_NULL_INDEX) {
    if (totalNumber <= maxNumber) return true;
  } else if (totalNumber <= maxNumber + 1) return true;
  return false;
};

export const openFileDialog = (inputRef: any): void => {
  if (inputRef.current) inputRef.current.click();
};

export const getAcceptTypeString = (
  acceptType?: Array<string>,
  allowNonImageType?: boolean,
) => {
  if (acceptType?.length) return acceptType.map(item => `.${item}`).join(', ');
  if (allowNonImageType) return '';
  return 'image/*';
};

export const getBase64 = (file: File): Promise<string> => {
  const reader = new FileReader();
  return new Promise(resolve => {
    reader.addEventListener('load', () => resolve(String(reader.result)));
    reader.readAsDataURL(file);
  });
};

export const getImage = (file: File): Promise<HTMLImageElement> => {
  const image = new Image();
  return new Promise(resolve => {
    image.addEventListener('load', () => resolve(image));
    image.src = URL.createObjectURL(file);
  });
};

export const getListFiles = (
  files: FileList,
  dataURLKey: string,
): Promise<ImageListType> => {
  const promiseFiles: Array<Promise<string>> = [];
  for (let i = 0; i < files.length; i += 1) {
    promiseFiles.push(getBase64(files[i]));
  }
  return Promise.all(promiseFiles).then((fileListBase64: Array<string>) => {
    const fileList: ImageListType = fileListBase64.map((base64, index) => ({
      [dataURLKey]: base64,
      file: files[index],
    }));
    return fileList;
  });
};
export const getErrorValidation = async ({
  fileList,
  value,
  maxNumber,
  keyUpdate,
  acceptType,
  maxFileSize,
  resolutionType,
  resolutionWidth,
  resolutionHeight,
  allowNonImageType,
}: IImageValidation): Promise<ErrorsType> => {
  const newErrors: ErrorsType = {};
  if (!isMaxNumberValid(fileList.length + value.length, maxNumber, keyUpdate)) {
    newErrors.maxNumber = true;
  } else {
    for (let i = 0; i < fileList.length; i += 1) {
      const { file } = fileList[i];
      if (!file) continue;
      if (!allowNonImageType && !isImageValid(file.type)) {
        newErrors.acceptType = true;
        break;
      }
      if (!isAcceptTypeValid(acceptType, file.name)) {
        newErrors.acceptType = true;
        break;
      }
      if (!isMaxFileSizeValid(file.size, maxFileSize)) {
        newErrors.maxFileSize = true;
        break;
      }
      if (resolutionType) {
        const image = await getImage(file);
        const checkRes = isResolutionValid(
          image,
          resolutionType,
          resolutionWidth,
          resolutionHeight,
        );
        if (!checkRes) {
          newErrors.resolution = true;
          break;
        }
      }
    }
  }
  if (Object.values(newErrors).find(Boolean)) return newErrors;
  return null;
};

export const getDefaultWorkHours = () => {
  const currentDay = dayjs(new Date()).format('YYYY-MM-DD');
  const startTime = dayjs(new Date(currentDay + ' 09:00:00')).format();
  const endTime = dayjs(new Date(currentDay + ' 21:00:00')).format();

  return [
    {
      day: 'Monday',
      open: startTime,
      close: endTime,
      selected: true,
    },
    {
      day: 'Tuesday',
      open: startTime,
      close: endTime,
      selected: true,
    },
    {
      day: 'Wednesday',
      open: startTime,
      close: endTime,
      selected: true,
    },
    {
      day: 'Thursday',
      open: startTime,
      close: endTime,
      selected: true,
    },
    {
      day: 'Friday',
      open: startTime,
      close: endTime,
      selected: true,
    },
    {
      day: 'Saturday',
      open: startTime,
      close: endTime,
      selected: true,
    },
    {
      day: 'Sunday',
      open: startTime,
      close: endTime,
      selected: true,
    },
  ];
};

export const getDefaultLocationForm = () => {
  return {
    category: '',
    map: '',
    name: '',
    state: 1,
    status: 1,
    twitter: '',
    facebookg: '',
    website: '',
    tags: [],
    phone: '',
    workHours: getDefaultWorkHours(),
    originalLogo: '',
    images: [],
  };
};

export const getDefaultEventForm = () => {
  return {
    title: '',
    description: '',
    contentDetail: '',
    bannerImage: '',
    iconImage: '',
    contentUrl: '',
    startTime: '',
    endTime: '',
    showStartTime: '',
    showEndTime: '',
    type: 1,
    tags: [],
    images: [],
    locations: [],
  };
};

export const defaultPropertyFormValue = () => ({
  latitude: '',
  longitude: '',
  name: '',
  text: '',
  id: '1',
  external_id: 1,
  asset: 1,
  location: '',
  location_name: '',
  fontFamily: 1,
  font_style: 'normal',
  fontSize: '24',
  height: 100,
  altitude: 100,
  belvel_size: 3,
  belvel_thickness: 0.3,
  belvel_segment: 1,
  belvel_offset: 20,
  color: '#fff',
  strokeColor: '#fff',
  opacity: 0.2,
  images: [],
  locked: false,
  isDeleted: false,
  edge_offset: 0,
  main_path: false,
  connection_group: null,
});

export const defaultCameraFormValue = () => ({
  hemisphone: '',
  directional: '',
  perspective: '',
  position_x: '',
  position_y: '',
  position_z: '',
  rotation_x: '',
  rotation_y: '',
  rotation_z: '',
  width: '',
  height: '',
  extrusion_scale: '',
  render_sampling: '',
});

export const options = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
];

export function deepEqual(obj1: Object | any, obj2: Object | any) {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 == 'object' &&
    obj1 != null &&
    typeof obj2 == 'object' &&
    obj2 != null
  ) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (!obj2.hasOwnProperty(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  return false;
}

export const isHTMLSVGElement = (node: Node | null): node is SVGElement => {
  // lower-casing due to XML/HTML convention differences
  // https://johnresig.com/blog/nodename-case-sensitivity
  return node?.nodeName.toLowerCase() === 'svg';
};

export const normalizeSVG = async (SVGString: string) => {
  const SVG_NS = 'http://www.w3.org/2000/svg';

  const doc = new DOMParser().parseFromString(SVGString, 'image/svg+xml');
  const svg = doc.querySelector('svg');
  const errorNode = doc.querySelector('parsererror');
  if (errorNode || !isHTMLSVGElement(svg)) {
    throw new Error('invalid svg');
  } else {
    if (!svg.hasAttribute('xmlns')) {
      svg.setAttribute('xmlns', SVG_NS);
    }

    if (!svg.hasAttribute('width') || !svg.hasAttribute('height')) {
      const viewBox = svg.getAttribute('viewBox');
      let width = svg.getAttribute('width') || '50';
      let height = svg.getAttribute('height') || '50';
      if (viewBox) {
        const match = viewBox.match(/\d+ +\d+ +(\d+) +(\d+)/);
        if (match) {
          [, width, height] = match;
        }
      }
      svg.setAttribute('width', width);
      svg.setAttribute('height', height);
    }

    return svg.outerHTML;
  }
};

export const transformSelectOptions = (
  data: Record<string, any>[],
  isCountry = false,
) => {
  return data?.map(it => ({
    value: isCountry ? it.name : it.id,
    label: it.name,
    title: it.name,
  }));
};

export const transformSelectOptionsWithTranslation = (
  data: Record<string, any>[],
  cb: (str: string) => string,
) => {
  return data?.map(it => ({
    value: it.value,
    label: cb(it.label),
    title: cb(it.title),
  }));
};

export const getCountryFromTokenOptions = (data: Record<string, any>[]) => {
  const countries: string[] = [];

  data.forEach((it: any) => {
    if (!countries.includes(it.country) && it.country) {
      countries.push(it.country);
    }
  });

  return countries.map(it => ({
    value: it,
    label: it,
    title: it,
  }));
};

export const formatWorkHours = (dates: any[]) => {
  return dates
    ?.filter(it => it.selected)
    ?.map(it => ({
      day: it.day,
      open: dayjs(new Date(it.open)).format('HH:mm'),
      close: dayjs(new Date(it.close)).format('HH:mm'),
    }));
};

export const formatWorkingTime = (date: string) => {
  return dayjs(new Date(date)).format('HH:mm');
};

export const transformWokHours = (
  data: any,
  format: boolean = false,
  notShownFull: boolean = false,
  cb: any = null,
) => {
  const currentDay = dayjs(new Date()).format('YYYY-MM-DD');
  const startTime = format
    ? '09:00'
    : dayjs(new Date(currentDay + ' 09:00:00')).format();
  const endTime = format
    ? '21:00'
    : dayjs(new Date(currentDay + ' 21:00:00')).format();
  const weekDays = format
    ? ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    : [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ];
  if (notShownFull) {
    return weekDays
      .filter(it => _.find(data?.workHours, item => item.day?.includes(it)))
      ?.map(day => {
        const found = _.find(data?.workHours, item => item.day?.includes(day));
        return {
          day: cb ? cb(day) : day,
          open: `${found?.open}`,
          close: `${found?.close}`,
        };
      });
  }

  return weekDays.map(it => {
    const found = _.find(data?.workHours, item => item.day === it);
    if (found) {
      return {
        day: cb ? cb(it) : it,
        open: format
          ? `${found.open}`
          : dayjs(new Date(`${currentDay} ${found.open}:00`)).format(),
        close: format
          ? `${found.close}`
          : dayjs(new Date(`${currentDay} ${found.close}:00`)).format(),
        selected: true,
      };
    } else {
      return {
        day: cb ? cb(it) : it,
        open: startTime,
        close: endTime,
        selected: false,
      };
    }
  });
};

export const getBase64FromUrl = async (url: string) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const formatDateTime = (
  dateTime: string,
  format: string = 'YYYY-MM-DD HH:mm',
) => {
  return dayjs(new Date(dateTime)).format(format);
};

export const formatNumber = (numb?: number | string) => {
  let nf = new Intl.NumberFormat('en-US');
  return numb ? nf.format(+numb) : 0;
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      // toast
    })
    .catch(err => {
      console.error('Failed to copy: ', err);
    });
};

export const roundNumber = (num: number) => {
  return Math.round(num * 100) / 100;
};

export const getTotal = (data: any[]) => {
  return roundNumber(
    (data.reduce((acc, currentValue) => acc + currentValue?.tokenBalance, 0) *
      100) /
      data[0]?.token_account?.supply,
  );
};

export const mappingSnipStatus = (status: number) => {
  return SNIP_STATUS[status];
};

export const convertStringToNumber = (nu: string) => {
  const num = nu?.toString().replace(/,/g, '');
  return +num;
};

export const validateKey = (privateKey: string) => {
  const { Keypair } = solanaWeb3;
  try {
    Keypair.fromSecretKey(new Uint8Array(bs58.decode(privateKey)));
    return true;
  } catch (e) {
    return false;
  }
};

export const validatePublicKey = (publicKey: string) => {
  const { PublicKey } = solanaWeb3;
  try {
    const address = new PublicKey(publicKey);
    return PublicKey.isOnCurve(address);
  } catch (e) {
    return false;
  }
};

export const setErrorWithTime = (fun: any, duration = 2000) => {
  return setTimeout(fun, duration);
};

export function validateNumberInRange(value) {
  const number = parseFloat(value);
  if (!isNaN(number) && number >= 0.0001 && number <= 100) {
    return true;
  } else {
    return false;
  }
}

export const getAddressFromPrivateKey = (privateKey: string) => {
  const { Keypair } = solanaWeb3;
  try {
    const keypair = Keypair.fromSecretKey(
      new Uint8Array(bs58.decode(privateKey)),
    );
    return keypair.publicKey.toBase58();
  } catch (e) {}
};

export const handleValidAddressAndNumber = (
  value: any,
  errorMessage: string,
  typeValid: string,
) => {
  let errorCount = 0;
  let errorLines: any = [];

  const handleValue = value?.map((item, index) => {
    const [address, amount] = value[index].split(',');

    const validateKeyAndNumber =
      typeValid === 'private'
        ? validateKey(address)
        : validatePublicKey(address);

    if (!validateKeyAndNumber) {
      errorCount++;
      errorLines.push(index + 1);
    }
    return {
      receiver: address.trim(),
      amount: amount,
    };
  });

  return {
    isValid: errorCount === 0,
    errorLines: errorLines,
    messageError: `${errorMessage} ${errorLines.join(' , ')}`,
    handleValue,
  };
};

export const encodedTransaction = transaction => {
  try {
    const recoveredTransaction = Transaction.from(
      Buffer.from(transaction, 'base64'),
    );
    return recoveredTransaction;
  } catch (error) {
    console.error('Error decoding transaction:', error);
  }
};

function splitArrayIntoChunks(array: any[], chunkSize: number) {
  const result: any = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
  }
  return result;
}
//get SOL for multi wallet
export async function getMultiSolBalance(
  connection: Connection,
  walletAddresses: PublicKey[],
): Promise<number[]> {
  const chunksSize = 10;
  const chunkedWallets = splitArrayIntoChunks(walletAddresses, chunksSize);
  const result: any = [];
  for (const wallets of chunkedWallets) {
    const accountInfos = await connection.getMultipleAccountsInfo(wallets);
    result.push(...accountInfos.map(accountInfo => accountInfo?.lamports || 0));
  }

  return result;
}

//get balance of token each wallet
export const getWalletBalanceToken = async (
  connection: Connection,
  account: Keypair | PublicKey,
  token: string,
) => {
  let address: PublicKey;
  if (account instanceof Keypair) {
    address = account.publicKey;
  } else {
    address = account;
  }

  const tokenAccounts = await connection.getTokenAccountsByOwner(
    address,
    {
      programId: TOKEN_PROGRAM_ID,
      mint: new PublicKey(token),
    },
    connection.commitment,
  );

  if (tokenAccounts.value.length <= 0) {
    return '0';
  }

  const tokenAccount = tokenAccounts.value[0];
  const accountData = AccountLayout.decode(tokenAccount.account.data);
  return String(accountData.amount);
};

export const calculatePNL = (initial: number, worth: number): number => {
  const pnl = new BigNumber(worth).minus(new BigNumber(initial));
  return initial == 0
    ? 0
    : pnl.div(new BigNumber(initial)).multipliedBy(100).toNumber();
};

//convert token
export const toTokenAmount = (
  precisionAmount: string | number,
  decimals: number,
) => {
  return Big(precisionAmount)
    .div(10 ** decimals)
    .toFixed(2);
};

//convert sol
export const lamportToSol = (lamport: string | number) => {
  return Big(lamport).div(LAMPORTS_PER_SOL).toFixed(4);
};
