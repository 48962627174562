/* eslint-disable max-lines */
import { PaginationProps } from '@/components/common/pagination';
import { Text } from '@/components/layouts/table/component';
import usePumpHook from '@/hooks/usePumpHook';
import { getTotal, toTokenAmount } from '@/utils/helper';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export enum TableType {
  VENUE = 'VENUE',
  PUMPFUN_TOKEN = 'PUMPFUN_TOKEN',
  TOP = 'TOP',
  DETAIL = 'DETAIL',
  BOOT_TX = 'BOOT_TX',
  BOOT_VOLUME = 'BOOT_VOLUME_TX',
  BOOT_BUY_SELL_TX = 'BOOT_TX_BUY_SELL',
  PUMP_BOOT_TX = 'PUMP_BOOT_TX',
  PUMP_BOOT_VOLUME = 'PUMP_BOOT_VOLUME',
  PUMP_BOOT_BUY_SELL_TX = 'PUMP_BOOT_BUY_SELL_TX',
  LIST_TRANSACTION = 'LIST_TRANSACTION',
  PUMPFUN_TRADER_DETAIL = 'PUMPFUN_TRADER_DETAIL',
  PUMPFUN_TRADER_LIST = 'PUMPFUN_TRADER_LIST',
}

export interface CommonTableProps {
  control?: any;
  type: TableType;
  data: Record<string, any>[];
  pagination?: PaginationProps;
  typeBoot?: string;
  onDelete?: (id: number) => void;
  onAction?: () => void;
  onReload?: () => void;
  idToken?: number | string;
}

export interface TagProps {
  name: string;
  id: number;
}

export interface ICheckHeader {
  indeterminate?: boolean;
  onChange?: (e: any) => void;
}

export interface HeaderProps {
  actionHeader?: () => void;
  holders?: any[];
}

export const TokenHeader = () => {
  const { t } = useTranslation();

  return (
    <TableHead className="first">
      <TableRow>
        <TableCell className="center top ">#</TableCell>
        <TableCell className="width_50">{t('Token Name')}</TableCell>
        {/* <TopTableHeader>{t('Image')}</TopTableHeader> */}
        <TableCell className="width_30">{t('Symbol')}</TableCell>
        <TableCell className="width_200">CA</TableCell>
        <TableCell className="width_100">{t('Supply')}</TableCell>
        <TableCell className="last">{t('Action')}</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const TopHeader = ({ actionHeader, holders }: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <TableHead className="first">
      <TableRow>
        <TableCell className="center">#</TableCell>
        <TableCell className="width_600">{t('Address')}</TableCell>
        <TableCell className="width_200">{t('Quantity')}</TableCell>
        <TableCell className="width_100">
          {t('Percentage')}{' '}
          <Text>
            Total: <Text className="bold">{holders && getTotal(holders)}</Text>%
          </Text>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export const PumpfunTokenHeader = () => {
  const { t } = useTranslation();

  return (
    <TableHead className="first">
      <TableRow>
        <TableCell className="center top ">#</TableCell>
        <TableCell className="width_50">{t('Token Name')}</TableCell>
        {/* <TopTableHeader>{t('Image')}</TopTableHeader> */}
        <TableCell className="width_30">{t('Symbol')}</TableCell>
        <TableCell className="width_200">CA</TableCell>
        <TableCell className="width_100">{t('Supply')}</TableCell>
        <TableCell className="last">{t('Action')}</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const SnipHeader = () => {
  const { t } = useTranslation();

  return (
    <TableHead className="first">
      <TableRow>
        <TableCell className="center no">#</TableCell>
        <TableCell className="width_5">{t('Address')}</TableCell>
        <TableCell className="width_2">{t('Amount')}</TableCell>
        <TableCell className="width_1">{t('Status')} </TableCell>
        <TableCell className="">{t('TX')}</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const BootTxHeader = () => {
  return (
    <TableHead className="first">
      <TableRow>
        <TableCell sx={{ width: '5%' }}>#</TableCell>
        <TableCell sx={{ width: '55%' }}>Contract Address</TableCell>
        <TableCell sx={{ width: '15%' }}>Action</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const BootTxBuySellHeader = () => {
  return (
    <TableHead className="first">
      <TableRow>
        <TableCell sx={{ width: '5%' }}>#</TableCell>
        <TableCell sx={{ width: '75%' }}>Wallet</TableCell>
        <TableCell sx={{ width: '20%' }}>Volume</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const TransactionHeader = () => {
  return (
    <TableHead className="first">
      <TableRow>
        <TableCell sx={{ width: '100%' }}>Transaction</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const PumpfunTraderHeader = (data: any) => {
  const location = useLocation();
  const id = location.pathname.split('/').pop();

  const [totalTokenBalance, setTotalTokenBalance] = useState('');
  const [infoToken, setInfoToken] = useState<any>({});
  const { listPumpToken, requestGetListPumpToken } = usePumpHook();

  const calculatorPercent = value => {
    if (!totalTokenBalance) return;
    if (!infoToken.decimal) return;
    const totalToken = toTokenAmount(totalTokenBalance, infoToken?.decimal);
    const result = totalToken / infoToken.supply;
    return Math.round(result * 100) / 100;
  };

  const handleReturnToTokenAmount = () => {
    if (totalTokenBalance) {
      const toTokenAmountParse = parseFloat(
        toTokenAmount(totalTokenBalance, infoToken?.decimal),
      ).toLocaleString('en-US');
      const percent = calculatorPercent(totalTokenBalance);
      return `${toTokenAmountParse} - (${percent})%`;
    }
    return '';
  };

  useEffect(() => {
    const totalToken =
      data.data.length > 0
        ? data.data.reduce((total, item) => {
            return total + BigInt(item.tokenBalance);
          }, BigInt(0))
        : '';

    setTotalTokenBalance(totalToken);
  }, [data]);
  /** fetch data based on query string */
  useEffect(() => {
    setTimeout(() => {
      requestGetListPumpToken();
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);
  useEffect(() => {
    if (listPumpToken.length > 0 && id) {
      const valueDecimal = listPumpToken.find(item => item.id == id);
      setInfoToken(valueDecimal);
    }
  }, [listPumpToken]);

  return (
    <TableHead className="first">
      <TableRow>
        <TableCell sx={{ width: '5%' }}>No</TableCell>
        <TableCell sx={{ width: '25%' }}>Wallet</TableCell>
        <TableCell sx={{ width: '13%' }}>SOL Balance</TableCell>
        <TableCell sx={{ width: '20%' }}>
          <div>Token</div>
          <div>{handleReturnToTokenAmount()}</div>
        </TableCell>
        <TableCell sx={{ width: '12%' }}>Profit</TableCell>
        <TableCell sx={{ width: '10%' }}>Active</TableCell>
        <TableCell sx={{ width: '10%' }}></TableCell>
      </TableRow>
    </TableHead>
  );
};
