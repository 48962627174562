/* eslint-disable max-lines */
import { Loader } from '@/components/common/loader';
import { CommonContainer } from '@/components/layouts/content';
import { ConfigTraderPopup } from '@/components/layouts/pumpfun/configTraderPopup';
import { PumpfunCard } from '@/components/layouts/pumpfun/pumpfunTraderCard';
import { CommonTable } from '@/components/layouts/table';
import { TableType } from '@/components/layouts/table/table';
import { Title } from '@/components/login';
import { ConfirmDeletePopup } from '@/components/token/components/confirmDeletePopup';
import { ImportWalletPopup } from '@/components/token/components/importWalletPopup';
import { RequestStatus } from '@/constants/API';
import { useAppDispatch } from '@/hooks';
import useGlobalHook from '@/hooks/useGlobalHook';
import usePumpHook from '@/hooks/usePumpHook';
import { mainRequest } from '@/services';
import { updateListTraderActive } from '@/stores/slices/pump/pump';
import {
  calculatePNL,
  checkIsRequesting,
  getMultiSolBalance,
  getWalletBalanceToken,
} from '@/utils/helper';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, Grid, Link, ThemeProvider, createTheme } from '@mui/material';
import { Connection, PublicKey } from '@solana/web3.js';
import BigNumber from 'bignumber.js';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
export interface DeleteForm {
  input: string;
}

const lightTheme = createTheme({ palette: { mode: 'light' } });

export enum TypeTrader {
  BUY = 'buy',
  SELL = 'sell',
}
export const PumpfunTraderDetail = () => {
  const { setErrorMsg, setSuccessMsg } = useGlobalHook();
  const { t } = useTranslation();
  const [open, setOpen] = useState({
    importWallet: false,
    deleteAll: false,
    walletConfig: false,
    traderConfig: false,
  });
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [listDataSignature, setListDataSignature] = useState<any>([]);
  const [dataListWallet, setDataListWallet] = useState<any>([]);

  const dispatch = useAppDispatch();
  const queries = useParams();
  const location = useLocation();
  const { id } = queries;

  const NETWORK = process.env.REACT_APP_RFC_URL;
  if (!NETWORK) {
    throw new Error('REACT_APP_RFC_URL environment variable is not set');
  }
  const connection = new Connection(NETWORK, {
    commitment: 'confirmed',
  });

  const {
    requestGetListPumpWallet,
    requestImportPumpWallet,
    requestDeletePumpWallet,
    getListPumpWalletStatus,
    importPumpWalletStatus,
    deletePumpWalletStatus,
    buyPumpWalletStatus,
    sellPumpWalletStatus,
    listPumpWallet,
    activeWalletStatus,
    unActiveWalletStatus,
    updateSettingTradeStatus,
    requestUpdateSettingTrade,
    requestActiveWallet,
    requestUnActiveWallet,
    requestResetPump,
  } = usePumpHook();

  const listWalletAddress = listPumpWallet?.map(item => item.address);

  const walletAddresses = listWalletAddress?.map(item => new PublicKey(item));
  const CA = localStorage.getItem('CA');

  const setDataBalances = async () => {
    // Fetch SOL balances
    const solBalances = await getMultiSolBalance(connection, walletAddresses);

    // Fetch token balances, if CA exists
    const tokenBalances = CA
      ? await Promise.all(
          walletAddresses.map(item =>
            getWalletBalanceToken(connection, item, CA),
          ),
        )
      : Array(walletAddresses.length).fill(null); // Default null if CA doesn't exist

    //Fetch profit
    const profitTrader = listPumpWallet.map(async (item, index) => {
      const payload = { tokenId: id, walletId: [item.id] };
      const response = await mainRequest('/trader/profit', payload, 'get');
      const worth =
        response.data.solPrice <= 0
          ? 0
          : new BigNumber(tokenBalances[index])
              .div(new BigNumber(10).pow(response?.data?.decimal))
              .multipliedBy(Number(response?.data?.tokenPrice) || 0)
              .div(response?.data?.solPrice)
              .toNumber();

      return calculatePNL(response.data.initial, worth);
    });

    // Merge balances into dataListWallet
    const result = listPumpWallet?.map((item, index) => ({
      ...item,
      solBalance: solBalances[index],
      tokenBalance: tokenBalances[index],
      profit: profitTrader[index],
    }));

    setDataListWallet(result);
  };

  useEffect(() => {
    setDataBalances();
  }, [listPumpWallet]);

  const reloadData = () => {
    requestGetListPumpWallet();
  };

  const loading = useMemo(
    () =>
      checkIsRequesting([
        getListPumpWalletStatus,
        importPumpWalletStatus,
        deletePumpWalletStatus,
        buyPumpWalletStatus,
        sellPumpWalletStatus,
        unActiveWalletStatus,
        updateSettingTradeStatus,
        activeWalletStatus,
      ]),
    [
      getListPumpWalletStatus,
      importPumpWalletStatus,
      deletePumpWalletStatus,
      buyPumpWalletStatus,
      sellPumpWalletStatus,
      activeWalletStatus,
      unActiveWalletStatus,
      updateSettingTradeStatus,
    ],
  );

  const handleImportWalletBuy = (payload: any) => {
    requestImportPumpWallet(payload);
    setOpen({ ...open, importWallet: false });
  };

  const handleDeleteAll = () => {
    const ids = listPumpWallet.map(item => item.id);
    requestDeletePumpWallet({ walletIds: ids });
    setOpen({ ...open, deleteAll: false });
  };

  const handleTraderConfig = (data: any) => {
    requestUpdateSettingTrade({
      slipBuyPercent: data.slipBuyPercent,
      slipSellPercent: data.slipSellPercent,
      gasBuy: data.gasBuy,
      gasSell: data.gasSell,
      tipMEV: data.tipMEV,
    });
    setOpen({ ...open, traderConfig: false });
  };

  const handleWalletConfig = () => {};

  const handleSubmitBuyAndSell = async (
    data: any,
    value: string,
    type: TypeTrader,
  ) => {
    const ids = listPumpWallet.map(item => item.id);

    let nameAPI;
    let body;

    if (type === TypeTrader.BUY) {
      //api buy
      body = {
        tokenId: queries.id && +queries.id,
        walletIds: ids,
        amount: value,
      };
      nameAPI = '/trader/buy';
    }
    if (type === TypeTrader.SELL) {
      //api sell
      body = {
        tokenId: queries.id && +queries.id,
        walletIds: ids,
        percent: value,
      };
      nameAPI = '/trader/sell';
    }
    const response = await mainRequest(`${nameAPI}`, body, 'post');

    try {
      const dataTransaction = response?.data?.data || [];
      setListDataSignature(prevDataArray => [
        ...prevDataArray,
        ...dataTransaction,
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleEnableAllWallet = () => {
    const allActive = dataListWallet.filter(item => item.isActive !== true);
    if (allActive.length > 0) {
      const listWallet = allActive.map(item => item.id);
      requestActiveWallet({ walletIds: listWallet });
    }

    reloadData();
  };

  const handleDisableAllWallet = () => {
    const allUnActive = dataListWallet.filter(item => item.isActive !== false);
    if (allUnActive.length > 0) {
      const listWallet = allUnActive.map(item => item.id);
      requestUnActiveWallet({ walletIds: listWallet });
    }
    reloadData();
  };

  useEffect(() => {
    dispatch({
      type: updateListTraderActive.type,
      payload: {
        active: 'reset',
        data: [],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** fetch data based on query string */
  useEffect(() => {
    setTimeout(() => {
      reloadData();
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (importPumpWalletStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Please check again!')]);
    }
    if (importPumpWalletStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Wallet is imported!')]);
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importPumpWalletStatus]);

  useEffect(() => {
    if (buyPumpWalletStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to buy!')]);
    }
    if (buyPumpWalletStatus === RequestStatus.SUCCESS) {
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyPumpWalletStatus]);

  useEffect(() => {
    if (sellPumpWalletStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong. Unable to sell!')]);
    }
    if (sellPumpWalletStatus === RequestStatus.SUCCESS) {
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellPumpWalletStatus]);

  useEffect(() => {
    if (deletePumpWalletStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong.Please check again! ')]);
    }
    if (deletePumpWalletStatus === RequestStatus.SUCCESS) {
      setSuccessMsg([t('Wallet Deleted!')]);
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePumpWalletStatus]);

  useEffect(() => {
    if (activeWalletStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong.Please check again! ')]);
    }
    if (activeWalletStatus === RequestStatus.SUCCESS) {
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWalletStatus]);

  useEffect(() => {
    if (unActiveWalletStatus === RequestStatus.ERROR) {
      setErrorMsg([t('Something went wrong.Please check again! ')]);
    }
    if (unActiveWalletStatus === RequestStatus.SUCCESS) {
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unActiveWalletStatus]);

  useEffect(() => {
    return () => requestResetPump();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CommonContainer className="top16">
      <Box sx={{ display: 'flex', gap: '8px', marginBottom: 1 }}>
        <button
          className={`multi_color_btn button main_button_new font_weight_500`}
          onClick={() => setOpen({ ...open, importWallet: true })}
        >
          <DownloadForOfflineIcon
            sx={{
              color: '#fff',
              width: '24px !important',
              height: '24px !important',
            }}
          />
          Import
        </button>
        <button
          className={`multi_color_btn button main_button_new font_weight_500`}
          onClick={() => setOpen({ ...open, deleteAll: true })}
        >
          <DeleteIcon
            sx={{
              color: '#fff',
              width: '24px !important',
              height: '24px !important',
            }}
          />
          Remove All
        </button>
        <button
          className={`multi_color_btn button main_button_new font_weight_500`}
          onClick={() => setOpen({ ...open, traderConfig: true })}
        >
          <TuneIcon
            sx={{
              color: '#fff',
              width: '24px !important',
              height: '24px !important',
            }}
          />
          Trader Config
        </button>
        <button
          className={`multi_color_btn button main_button_new font_weight_500`}
          onClick={handleEnableAllWallet}
        >
          <ToggleOnIcon
            sx={{
              color: '#fff',
              width: '24px !important',
              height: '24px !important',
            }}
          />
          Active All
        </button>
        <button
          className={`multi_color_btn button main_button_new font_weight_500`}
          onClick={handleDisableAllWallet}
        >
          <ToggleOffIcon
            sx={{
              color: '#fff',
              width: '24px !important',
              height: '24px !important',
            }}
          />
          InActive All
        </button>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={10}>
          <CommonTable
            type={TableType.PUMPFUN_TRADER_DETAIL}
            data={dataListWallet}
            onReload={reloadData}
            idToken={id ? Number(id) : ''}
          />
          <PumpfunCard handleSubmitBuyAndSell={handleSubmitBuyAndSell} />
        </Grid>
        <Grid item xs={2}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              border: '1px solid #333',
              borderRadius: 1,
              backgroundColor: '#f0f0f0',
              width: '100%',
            }}
          >
            <Title>Transaction</Title>
            {[lightTheme].map((theme, index) => (
              <Box
                key={index}
                sx={{
                  maxHeight: '590px',
                  overflow: 'auto',
                  width: '-webkit-fill-available',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: '#f0f0f0',
                    height: 400,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '10px',
                    backgroundColor: '#555',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#555',
                  },
                }}
              >
                <ThemeProvider theme={theme}>
                  <Box
                    sx={{
                      p: 2,
                      borderRadius: 1,
                      bgcolor: 'background.default',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {listDataSignature.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          flex: 1,
                          width: '100%',
                          wordWrap: 'nowrap',
                          cursor: 'pointer',
                          marginBottom: '2px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <Link
                          href={`https://solscan.io/tx/${item.signature}`}
                          target="_blank"
                          sx={{
                            wordWrap: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontFamily:
                              '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: 400,
                            fontSize: '0.8rem',
                            lineHeight: '1.43',
                            letterSpacing: '0.01071em',
                            '&:hover': {
                              color: '#0d47a1',
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          {item.signature}
                        </Link>
                      </Box>
                    ))}
                    {listDataSignature.length === 0 && (
                      <Box
                        sx={{
                          flex: 1,
                          width: '100%',
                          wordWrap: 'break-word',
                          fontFamily:
                            '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: 400,
                          fontSize: '0.9rem',
                          lineHeight: '1.43',
                          letterSpacing: '0.01071em',
                          paddingLeft: '12px',
                          textAlign: 'center',
                        }}
                      >
                        {isRunning ? 'Loading...' : 'No data'}
                      </Box>
                    )}
                  </Box>
                </ThemeProvider>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>

      <ImportWalletPopup
        open={open.importWallet}
        onSubmit={handleImportWalletBuy}
        onClose={() => setOpen({ ...open, importWallet: false })}
      />
      <ConfirmDeletePopup
        open={open.deleteAll}
        content={'Are you sure you want to delete all wallet?'}
        onSubmit={handleDeleteAll}
        onClose={() => setOpen({ ...open, deleteAll: false })}
      />

      <ConfigTraderPopup
        open={open.traderConfig}
        onSubmit={handleTraderConfig}
        onClose={() => setOpen({ ...open, traderConfig: false })}
      />

      <Loader show={loading} />
    </CommonContainer>
  );
};
